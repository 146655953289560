import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";
import eventBus from "../../eventBus";
//event bus
let userDomainId;
let setBusinessYearId;
eventBus.on("newUserLoggedIn", (data) => {
    userDomainId = data.userDomainId;
    setBusinessYearId = Number(localStorage.getItem("businessYearId"));
});
eventBus.on("newBusinessYearSelected", (data) => {
    setBusinessYearId = data.businessYearId;
});
let searchValue = null;
eventBus.on("searchValueDispatchNote", (data) => {
    searchValue = data;
});
export const dispatchNote = new CustomStore({
    key: "id",
    load: async (loadOptions) => {
        try {
            if (userDomainId) {
                let filterCondition = {
                    where: {
                        and: [
                            {
                                domainId: userDomainId
                            },
                            { businessYearId: setBusinessYearId }
                        ]
                    }
                };
                if (loadOptions.businessYearId || loadOptions.warehouseId) {
                    filterCondition = {
                        where: {
                            and: [
                                { domainId: userDomainId },
                                { businessYearId: loadOptions.businessYearId },
                                { warehouseId: loadOptions.warehouseId }
                            ]
                        },
                        order: "number DESC",
                        limit: 1
                    };
                }
                if (loadOptions.invoiceId) {
                    filterCondition = {
                        where: {
                            and: [
                                { domainId: userDomainId },
                                {
                                    businessYearId: loadOptions.businessYearId
                                },
                                {
                                    invoiceId: loadOptions.invoiceId
                                }
                            ]
                        }
                    };
                }
                if (searchValue !== null && searchValue !== "") {
                    filterCondition = {
                        where: {
                            and: [
                                {
                                    domainId: userDomainId
                                },
                                { businessYearId: setBusinessYearId }
                            ]
                        },
                        searchValue: searchValue
                    };
                    const newData = await sendRequest(
                        `${config.API_URL}/${
                            config.PATHS.DISPATCH_NOTE_FILTER
                        }?filter=${JSON.stringify(filterCondition)}`
                    );
                    // console.log(newData);
                    searchValue = null;
                    return newData;
                }
                const newData = await sendRequest(
                    `${config.API_URL}/${
                        config.PATHS.DISPATCH_NOTE
                    }?filter=${JSON.stringify(filterCondition)}`
                );
                return newData;
            }
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    byKey: async (key) => {
        try {
            const res = await sendRequest(
                `${config.API_URL}/${config.PATHS.DISPATCH_NOTE}/${key}`
            );
            return res;
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    },
    insert: async (values) => {
        try {
            const response = await sendRequest(
                `${config.API_URL}/${config.PATHS.DISPATCH_NOTE}`,
                "POST",
                {
                    values: values
                }
            );
            return response;
        } catch (error) {
            console.error("Error inserting data:", error);
            throw error;
        }
    },
    update: async (id, values) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.DISPATCH_NOTE}/${id}`,
                "PATCH",
                {
                    values: values
                }
            );
        } catch (error) {
            console.error("Error updating data:", error);
            throw error;
        }
    },
    remove: async (id) => {
        try {
            await sendRequest(
                `${config.API_URL}/${config.PATHS.DISPATCH_NOTE}/${id}`,
                "DELETE"
            );
        } catch (error) {
            console.error("Error removing data:", error);
            throw error;
        }
    }
});
