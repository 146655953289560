import { invoice } from "@/stores/invoiceStore";
export async function generateInvoiceData(
    adminId,
    domainId,
    businessYearId,
    warehouseId = null,
    offerData = null,
    userData = null,
    allDispatchNoteItems = null,
    selectedRowKeys = null
) {
    const totalAmount = allDispatchNoteItems
        ? allDispatchNoteItems.reduce((acc, item) => acc + item.priceTotal, 0)
        : 0;
    const now = new Date();
    const date = offerData ? new Date(offerData.date) : now;
    const dueDate = new Date(date.setDate(date.getDate() + 15));
    const lastInvoice = await invoice.load({ lastNumber: true });
    let lastNumber =
        lastInvoice && lastInvoice.length > 0
            ? Number(lastInvoice[0].number)
            : 0;
    const invoiceData = {
        number: Number(lastNumber) + 1,
        date: offerData ? offerData.date : now.toISOString(),
        delay: 15,
        dueDate: dueDate.toISOString(),
        delivery: offerData ? offerData.date : now.toISOString(),
        taxId: offerData ? offerData.taxId : 1,
        taxValue: offerData ? offerData.taxValue : 25,
        taxName: offerData ? offerData.taxName : "PDV 25%",
        taxAmount: offerData ? offerData.taxAmount : null,
        totalAmount: offerData ? offerData.totalAmount : totalAmount,
        totalDisocuntAmount: offerData ? offerData.totalDisocuntAmount : null,
        totalAmountDiscounted: offerData
            ? offerData.totalAmountDiscounted
            : null,
        totalTaxAmount: offerData ? offerData.totalTaxAmount : null,
        totalAmountTaxed: offerData ? offerData.totalAmountTaxed : null,
        createdDate: now.toISOString(),
        modifiedDate: now.toISOString(),
        createdById: Number(adminId),
        modifiedById: Number(adminId),
        paymentMethod: "Virman",
        offerId: offerData ? offerData.id : null,
        dispatchNoteId: selectedRowKeys
            ? JSON.stringify(selectedRowKeys)
            : null,
        userId: offerData ? offerData.userId : userData.id,
        user: offerData ? offerData.user : JSON.stringify(userData),
        warehouseId: warehouseId ? warehouseId : null,
        businessYearId: Number(businessYearId),
        domainId: Number(domainId),
        status: "new",
        statusEInvoice: "not-sent"
    };
    return invoiceData;
}
