<template>
    <!-- <div class="table-container"> -->
    <p class="table-name">Početno stanje</p>
    <DxDataGrid
        id="initialStateTable"
        :data-source="initialState"
        :repaint-changes-only="true"
        :show-column-lines="true"
        :show-row-lines="true"
        :show-borders="true"
        :column-auto-width="true"
        :hover-state-enabled="true"
        :focused-row-enabled="true"
        :row-alternation-enabled="true"
        :allow-column-resizing="true"
        @initialized="getInitialStateTableInstance"
        @init-new-row="onInitNewRowInitialState"
        @row-inserting="onRowInsertingInitialState"
        @row-inserted="onRowInsertedInitialState"
        @editing-start="onEditingStartInitialState"
        @row-updating="onRowUpdatingInitialState"
        @row-updated="onRowUpdatedInitialState"
        @row-removing="onRowRemovingInitialState"
        @focused-row-changed="onFocusedRowChangedInitialState"
        @edit-canceled="onEditCanceledInitialState"
        edit-template="initialStateEditor"
    >
        <DxPaging :page-size="20" />
        <DxPager
            :visible="true"
            display-mode="adaptive"
            :show-info="true"
            info-text="Stranica {0} od {1} ({2} stavki)"
            :show-navigation-buttons="true"
            :show-page-size-selector="true"
            :allowed-page-sizes="[10, 20, 30]"
        />
        <DxStateStoring
            :enabled="true"
            type="localStorage"
            storage-key="initialStateTableState"
        />
        <DxToolbar>
            <DxItem
                :options="allStatusButtonOptions"
                location="before"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="activeStatusButtonOptions"
                location="before"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="deletedStatusButtonOptions"
                location="before"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                id="addButton"
                :options="initialStateAddButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="initialStateRefreshButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem
                :options="columnChooserButtonOptions"
                location="after"
                locate-in-menu="auto"
                widget="dxButton"
            />
            <DxItem name="searchPanel" location="after" locate-in-menu="auto" />
        </DxToolbar>
        <DxSearchPanel :visible="true" />
        <DxHeaderFilter :visible="true" />
        <DxScrolling column-rendering-mode="virtual" />
        <DxEditing
            :allow-deleting="true"
            :allow-updating="true"
            :allow-adding="true"
            :use-icons="true"
            mode="popup"
        >
            <DxPopup
                :show-title="true"
                title="Informacije o početnom stanju"
                :toolbar-items="initialStatePopupToolbarItems"
                :drag-outside-boundary="true"
                @shown="onPopupShown"
            />
            <DxForm>
                <DxItem :col-count="3" :col-span="2" item-type="group">
                    <DxItem
                        data-field="id"
                        data-type="number"
                        :visible="false"
                    />
                    <DxItem item-type="group" col-count="3">
                        <DxItem
                            data-field="number"
                            data-type="number"
                            col-span="2"
                            :editor-options="numberOptions"
                        >
                            <DxRequiredRule />
                            <DxRangeRule :min="1" :max="2147483648" />
                        </DxItem>
                        <DxItem
                            data-field="businessYearId"
                            data-type="number"
                            :editor-options="businessYearSelectBoxOptions"
                        >
                            <DxLabel :visible="false"></DxLabel
                        ></DxItem>
                    </DxItem>

                    <DxItem
                        data-field="date"
                        data-type="date"
                        :editor-options="dateSelectOptions"
                    >
                        <DxRequiredRule />
                    </DxItem>
                    <DxItem data-field="remark" data-type="string">
                        <DxStringLengthRule
                            :max="250"
                            message="Opis ne može biti više od 250 znakova."
                        />
                    </DxItem>
                    <DxItem
                        data-field="warehouseId"
                        data-type="number"
                        editor-type="dxSelectBox"
                        :editor-options="warehouseSelectBoxOptions"
                        ><DxRequiredRule
                    /></DxItem>
                    <DxItem
                        data-field="modifiedById"
                        data-type="number"
                        :visible="false"
                    />
                    <DxItem
                        data-field="modifiedDate"
                        data-type="datetime"
                        :visible="false"
                    />
                </DxItem>
                <DxItem item-type="group" :col-span="2">
                    <DxItem col-span="3">
                        <template #default>
                            <DxDataGrid
                                id="initialStateItemTable"
                                :data-source="initialStateItemTableData"
                                :show-column-lines="true"
                                :show-row-lines="true"
                                :show-borders="true"
                                :column-auto-width="true"
                                @initialized="getInitialStateItemTableInstance"
                                @editing-start="onEditingStartInitialStateItem"
                                @row-inserting="onRowInsertingInitialStateItem"
                            >
                                <DxPaging :page-size="10" />
                                <DxPager
                                    :visible="true"
                                    display-mode="adaptive"
                                    :show-info="true"
                                    info-text="Stranica {0} od {1} ({2} stavki)"
                                    :allowed-page-sizes="[5, 10, 15]"
                                    :show-navigation-buttons="true"
                                    :show-page-size-selector="true"
                                />
                                <DxEditing
                                    :allow-deleting="true"
                                    :allow-updating="true"
                                    :allow-adding="true"
                                    :use-icons="true"
                                    mode="popup"
                                >
                                    <DxPopup
                                        :max-height="350"
                                        :max-width="650"
                                        :show-title="true"
                                        title="Informacije o stavci"
                                        :toolbar-items="
                                            initialStateItemPopupToolbarItems
                                        "
                                        :drag-outside-boundary="true"
                                    />
                                    <DxForm>
                                        <DxItem
                                            :col-count="10"
                                            :col-span="2"
                                            item-type="group"
                                        >
                                            <DxItem
                                                data-field="priceListItemId"
                                                data-type="number"
                                                editor-type="dxSelectBox"
                                                :editor-options="
                                                    priceListItemSelectBoxOptions
                                                "
                                                :col-span="9"
                                            >
                                                <DxRequiredRule
                                            /></DxItem>
                                            <DxItem
                                                :col-span="1"
                                                editor-type="dxButton"
                                                :editor-options="
                                                    addNewPriceListItemButtonOptions
                                                "
                                            />
                                        </DxItem>
                                        <DxItem
                                            :col-count="2"
                                            :col-span="2"
                                            item-type="group"
                                        >
                                            <DxItem
                                                data-field="price"
                                                data-type="number"
                                                :editor-options="priceOptions"
                                            >
                                                <DxRangeRule
                                                    :min="0"
                                                    :max="99999999999.99"
                                            /></DxItem>
                                            <DxItem
                                                data-field="qty"
                                                data-type="number"
                                                :editor-options="qtyOptions"
                                            >
                                                <DxRangeRule
                                                    :max="99999999999.99"
                                            /></DxItem>
                                            <DxItem
                                                data-field="priceTotal"
                                                data-type="number"
                                                :editor-options="
                                                    priceTotalOptions
                                                "
                                            >
                                                <DxRangeRule
                                                    :max="99999999999.99"
                                            /></DxItem>
                                            <DxItem
                                                data-field="Stanje na skladištu"
                                                data-type="number"
                                                :editor-options="
                                                    warehouseStatusOptions
                                                "
                                            />
                                        </DxItem>
                                    </DxForm>
                                </DxEditing>
                                <DxColumnFixing :enabled="true" />
                                <DxColumn
                                    caption="Rb."
                                    :width="50"
                                    alignment="center"
                                    :calculate-cell-value="setRowIndexItem"
                                    :allow-hiding="false"
                                    :show-in-column-chooser="false"
                                />
                                <DxColumn
                                    data-field="priceListItemId"
                                    data-type="number"
                                    caption="Roba"
                                >
                                    <DxLookup
                                        :data-source="priceListItem"
                                        value-expr="id"
                                        displayExpr="name"
                                    />
                                </DxColumn>
                                <DxColumn
                                    width="120"
                                    data-field="price"
                                    data-type="number"
                                    caption="Cijena"
                                    format="###,###,###,##0.00"
                                />
                                <DxColumn
                                    width="120"
                                    data-field="qty"
                                    data-type="number"
                                    caption="Količina"
                                    format="###,###,###,##0.00"
                                />
                                <DxColumn
                                    width="120"
                                    data-field="priceTotal"
                                    data-type="number"
                                    caption="Ukupna cijena"
                                    format="###,###,###,##0.00"
                                />
                                <DxSummary>
                                    <DxTotalItem
                                        column="priceTotal"
                                        summary-type="sum"
                                        :value-format="priceTotalFormat"
                                        display-format="{0}"
                                    />
                                </DxSummary>
                                <DxColumn
                                    type="buttons"
                                    :auto-width="true"
                                    :fixed="true"
                                >
                                    <DxButton
                                        hint="Uredi"
                                        icon="rename"
                                        :visible="true"
                                        @click="editInitialStateItem"
                                    />
                                    <DxButton
                                        hint="Obriši"
                                        icon="clear"
                                        :visible="true"
                                        @click="deleteInitialStateItem"
                                    />
                                </DxColumn>
                            </DxDataGrid>
                        </template>
                    </DxItem>
                </DxItem>
            </DxForm>
        </DxEditing>
        <DxColumn
            :width="80"
            data-field="id"
            data-type="number"
            caption="ID"
            :visible="false"
        />
        <DxColumn
            data-field="number"
            data-type="number"
            caption="Broj"
            :allow-hiding="false"
        />
        <DxColumn data-field="businessYearId" data-type="number" caption="God.">
            <DxLookup
                :data-source="businessYear"
                value-expr="id"
                display-expr="year"
            />
        </DxColumn>
        <DxColumn
            data-field="warehouseId"
            data-type="number"
            caption="Skladište"
        >
            <DxLookup
                :data-source="warehouse"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>
        <DxColumn
            data-field="date"
            data-type="date"
            caption="Datum"
            format="dd/MM/yyyy"
        />
        <DxColumn data-field="remark" data-type="string" caption="Opis" />
        <DxColumn
            data-field="priceTotal"
            data-type="number"
            caption="Ukupno"
            format="###,###,###,##0.00"
        />
        <DxColumn
            data-field="status"
            data-type="string"
            caption="Status"
            :allow-exporting="false"
            :allow-header-filtering="false"
        />
        <DxColumn data-field="createdById" data-type="number" caption="Kreirao">
            <DxLookup
                :data-source="domainUser"
                value-expr="id"
                display-expr="username"
            />
        </DxColumn>
        <DxColumn
            data-field="createdDate"
            data-type="datetime"
            caption="Kreirano"
            format="dd/MM/yyyy HH:mm"
        />
        <DxColumn data-field="modifiedById" data-type="number" caption="Uredio">
            <DxLookup
                :data-source="domainUser"
                value-expr="id"
                display-expr="username"
            />
        </DxColumn>
        <DxColumn
            data-field="modifiedDate"
            data-type="datetime"
            caption="Uređeno"
            format="dd/MM/yyyy HH:mm"
        />
        <DxColumn
            data-field="domainId"
            data-type="number"
            caption="Domena"
            :visible="false"
            :allow-hiding="false"
            :show-in-column-chooser="false"
        >
            <DxLookup
                :data-source="domain"
                value-expr="id"
                display-expr="name"
            />
        </DxColumn>
        <DxColumn
            type="buttons"
            :auto-width="true"
            :fixed="true"
            :show-in-column-chooser="false"
            ><DxButton icon="menu" @click="openContextMenu" />
            <!-- <DxButton
                hint="Uredi"
                icon="rename"
                :visible="isNotDeleted"
                @click="editInitialState"
            />
            <DxButton
                hint="Dupliciraj"
                icon="copy"
                @click="duplicateInitialState"
            />
            <DxButton
                hint="Ispiši PDF"
                icon="pdffile"
                :visible="isNotDeleted"
                @click="exportInitialState"
            />
            <DxButton
                hint="Učitaj stavke"
                icon="import"
                @click="importInitialStateItems"
                :visible="isNotDeleted"
            />
            <DxButton
                hint="Obriši"
                icon="trash"
                :visible="isNotDeleted"
                @click="deleteInitialState"
            /> -->
        </DxColumn>
        <DxColumnChooser :enabled="true" :mode="columnChooserMode">
            <DxColumnChooserSelection
                :allow-select-all="true"
                :select-by-click="true"
                :recursive="true"
            />
        </DxColumnChooser>
    </DxDataGrid>
    <AddPopup
        :show-title="true"
        title="Informacije o stavci"
        :drag-outside-boundary="true"
        :toolbar-items="addPopupToolbarItems"
        @initialized="getAddPopupInstance"
        @hiding="cancelNewPriceListItem"
    >
        <AddForm
            :form-data="formData"
            @initialized="getAddFormInstance"
            validationGroup="addPriceListItem"
        >
            <DxItem :col-count="3" :col-span="2" item-type="group">
                <DxItem data-field="id" data-type="number" :visible="false" />
                <DxItem
                    item-type="group"
                    :col-count="3"
                    :col-span="3"
                    caption="Cijenik"
                >
                    <DxItem
                        data-field="priceListId"
                        data-type="number"
                        editor-type="dxSelectBox"
                        :label="{ text: 'Cijenik' }"
                        :editor-options="priceListSelectBoxOptions"
                        ><DxRequiredRule
                    /></DxItem>
                </DxItem>
                <DxItem
                    item-type="group"
                    :col-count="3"
                    :col-span="3"
                    caption="Osnovne informacije"
                >
                    <DxItem
                        data-field="code"
                        data-type="string"
                        :label="{ text: 'Šifra' }"
                    >
                        <DxRequiredRule />
                    </DxItem>
                    <DxItem
                        data-field="name"
                        data-type="string"
                        :label="{ text: 'Naziv' }"
                        ><DxRequiredRule
                    /></DxItem>
                    <DxItem
                        data-field="stockNumber"
                        data-type="string"
                        :label="{ text: 'Kataloški broj' }"
                    />
                    <DxItem
                        data-field="foreignName"
                        data-type="string"
                        :label="{ text: 'Strani naziv' }"
                    />
                    <DxItem
                        data-field="ean"
                        data-type="string"
                        :label="{ text: 'EAN' }"
                    />
                    <DxItem
                        data-field="customsNumber"
                        data-type="string"
                        :label="{ text: 'Carinski broj' }"
                    />
                </DxItem>
                <DxItem
                    item-type="group"
                    :col-count="3"
                    :col-span="3"
                    caption="Tip/Dobavljač"
                >
                    <DxItem
                        data-field="measureUnit"
                        data-type="string"
                        :label="{ text: 'JMJ' }"
                        ><DxRequiredRule
                    /></DxItem>
                    <DxItem
                        data-field="vatId"
                        data-type="number"
                        :label="{ text: 'PDV' }"
                        editor-type="dxSelectBox"
                        :editor-options="vatIdOptions"
                        ><DxRequiredRule
                    /></DxItem>
                    <DxItem
                        data-field="itemType"
                        data-type="string"
                        editor-type="dxSelectBox"
                        :label="{ text: 'Roba/Usluga' }"
                        :editor-options="itemTypeOptions"
                        ><DxRequiredRule
                    /></DxItem>
                    <DxItem
                        data-field="supplierId"
                        data-type="number"
                        editor-type="dxSelectBox"
                        :label="{ text: 'Dobavljač' }"
                        :editor-options="supplierSelectBoxOptions"
                    ></DxItem>
                </DxItem>
                <DxItem
                    item-type="group"
                    :col-count="3"
                    :col-span="3"
                    caption="Informacije o cijeni"
                >
                    <DxItem
                        data-field="latestProcurementPrice"
                        data-type="number"
                        :disabled="true"
                        :label="{ text: 'Zadnja nabavna' }"
                    />
                    <DxItem
                        data-field="priceCalculationType"
                        data-type="string"
                        editor-type="dxSelectBox"
                        :label="{ text: 'Izračun cijene' }"
                        :editor-options="priceCalculationTypeOptions"
                    />
                    <DxItem
                        data-field="wholesalePrice"
                        data-type="number"
                        :label="{ text: 'Veleprodajna' }"
                        :editor-options="wholesalePriceOptions"
                    >
                        <DxRequiredRule
                    /></DxItem>
                    <DxItem
                        data-field="vatAmount"
                        data-type="number"
                        :label="{ text: 'Iznos PDV-a' }"
                        :editor-options="vatAmountOptions"
                    />
                    <DxItem
                        data-field="retailPrice"
                        data-type="number"
                        :label="{ text: 'Maloprodajna' }"
                        :editor-options="retailPriceOptions"
                        ><DxRequiredRule
                    /></DxItem>
                    <DxItem
                        data-field="margin"
                        data-type="number"
                        :label="{ text: 'Marža' }"
                    />
                </DxItem>
                <DxItem
                    item-type="group"
                    :col-count="3"
                    :col-span="3"
                    caption="Ostale informacije"
                >
                    <DxItem
                        data-field="returnPackagingQty"
                        data-type="number"
                        :label="{ text: 'Povratna ambalaža' }"
                    />
                    <DxItem
                        data-field="orderedQty"
                        data-type="number"
                        :label="{ text: 'Naručeno' }"
                    />
                    <DxItem
                        data-field="minStock"
                        data-type="number"
                        :label="{ text: 'Min. zaliha' }"
                    />
                    <DxItem
                        data-field="maxStock"
                        data-type="number"
                        :label="{ text: 'Max. zaliha' }"
                    />
                    <DxItem
                        data-field="hidden"
                        data-type="number"
                        :editor-options="hiddenOptions"
                        editor-type="dxSelectBox"
                        :label="{ text: 'Skriveno' }"
                    />
                </DxItem>
            </DxItem>
        </AddForm>
    </AddPopup>
    <AddPopup
        :resize-enabled="true"
        :drag-outside-boundary="true"
        width="600"
        height="500"
        :toolbarItems="importPriceListItemPopupItems"
        title="Učitaj podatke iz XLS/CSV datoteke"
        @initialized="getImportPriceListItemPopupInstance"
        @hiding="onPopupHiding"
    >
        <div
            style="
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            "
        >
            <span>Primjer XLS strukture (Stanje skladišta)</span>
            <img
                src="@/assets/example-xlsx-warehouse-item-status.png"
                style="margin-bottom: 3vh; width: 100%"
            />
            <!-- <a href="/example-xlsm.xlsm" download style="text-decoration: none"
                >Preuzmi primjer XLSM datoteke
            </a>
            <span style="font-size: 8px; margin-bottom: 1vh">
                *Potrebno je omogućiti izvođenje skripti zbog računanja
                cijena</span
            >
            <a
                href="/example-xlsx.xlsx"
                download
                style="margin-bottom: 1vh; text-decoration: none"
                >Preuzmi primjer XLSX datoteke
            </a>
            <a
                href="/example-csv.csv"
                download
                style="margin-bottom: 1vh; text-decoration: none"
                >Preuzmi primjer CSV datoteke
            </a> -->
            <DxFileUploader
                accept=".xlsx, .xls, .xlsm"
                uploadMode="useForm"
                selectButtonText="Odaberi datoteku"
                labelText="ili ispusti ovdje"
                @value-changed="onFileSelection"
                @initialized="getFileUploaderInstance"
            >
            </DxFileUploader>
        </div>
    </AddPopup>
    <DxContextMenu
        @initialized="getContextMenuInstance"
        showEvent="openContextMenu"
    />
    <!-- </div> -->
    <div class="split"></div>
</template>
<script>
//DevExpress
import {
    DxDataGrid,
    DxColumn,
    DxColumnFixing,
    DxScrolling,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxButton,
    DxToolbar,
    DxSearchPanel,
    DxHeaderFilter,
    DxLookup,
    DxRequiredRule,
    DxStateStoring,
    DxColumnChooser,
    DxColumnChooserSelection,
    DxPaging,
    DxPager,
    DxSummary,
    DxTotalItem,
    DxLabel,
    DxRangeRule,
    DxStringLengthRule
} from "devextreme-vue/data-grid";
import notify from "devextreme/ui/notify";
import DataSource from "devextreme/data/data_source";
import { DxPopup as AddPopup } from "devextreme-vue/popup";
import { DxForm as AddForm } from "devextreme-vue/form";
import { confirm } from "devextreme/ui/dialog";
import { DxFileUploader } from "devextreme-vue/file-uploader";
//Utils
import readXlsxFile from "read-excel-file";
// import Papa from "papaparse";
import { useInitialState } from "@/composables/useInitialState";
import { usePriceListItem } from "@/composables/usePriceListItem.js";
import { useImportItemStore } from "@/stores/customImportItemStore.js";
import { ref } from "vue";
import eventBus from "../../eventBus.js";
import DxContextMenu from "devextreme-vue/context-menu";
// import { updateWarehouseItemStatusOnNewInitialState } from "@/utils/updateWarehouseItemStatus.js";

export default {
    name: "initialStateTableComponent",
    components: {
        DxContextMenu,
        DxDataGrid,
        DxColumn,
        DxColumnFixing,
        DxScrolling,
        DxEditing,
        DxPopup,
        DxForm,
        DxItem,
        DxButton,
        DxToolbar,
        DxSearchPanel,
        DxHeaderFilter,
        DxLookup,
        DxRequiredRule,
        DxStateStoring,
        DxColumnChooser,
        DxColumnChooserSelection,
        DxPaging,
        DxPager,
        DxSummary,
        DxTotalItem,
        DxLabel,
        DxRangeRule,
        DxStringLengthRule,
        AddPopup,
        AddForm,
        DxFileUploader
    },
    setup() {
        //instances
        const tableInstance = ref(null);
        const itemTableInstance = ref(null);
        const dateSelectInstance = ref(null);
        const warehouseStatusInstance = ref(null);
        const numberInstance = ref(null);
        const warehouseInstance = ref(null);
        const priceListItemInstance = ref(null);
        const qtyInstance = ref(null);
        const priceInstance = ref(null);
        const priceTotalInstance = ref(null);
        const addFormInstance = ref(null);
        const addPopupInstance = ref(null);
        const vatAmountInstance = ref(null);
        const wholesalePriceInstance = ref(null);
        const retailPriceInstance = ref(null);
        const importPopupInstance = ref(null);
        const fileUploaderInstance = ref(null);
        const contextMenuInstance = ref(null);

        //general data
        const rowEvent = ref(null);
        const uploadedFileData = ref(null);
        const selectedDate = ref(null);
        const selectedRowKey = ref(null);
        const selectedInitialStateWarehouseId = ref(null);
        const priceListItemId = ref(null);
        const qty = ref(1);
        const price = ref(0);
        const priceTotal = ref(0);
        const number = ref(0);
        const duplicateInitialStateData = ref(null);
        const isEditing = ref(false);
        const formData = ref({
            vatId: 1,
            hidden: 0,
            priceCalculationType: "Fiksna cijena"
        });
        const {
            admin,
            domain,
            domainUser,
            warehouse,
            businessYear,
            initialState,
            initialStateItem,
            priceList,
            priceListItem,
            warehouseItemStatus,
            columnChooserMode,
            priceTotalFormat,
            businessYearId,
            rowsToBeDeleted,
            initialStateItemTableData,
            handleRowInsertingInitialState,
            handleRowInsertedInitialState,
            handleEditingStartInitialState,
            handleEditCanceledInitialState,
            handleRowUpdatingInitialState,
            handleRowRemovingInitialState,
            handleCancelInitialStateItem,
            handleSaveNewInitialStateItem,
            handleRowInsertingInitialStateItem,
            handleEditingStartInitialStateItem
        } = useInitialState();
        const {
            tax,
            vatId,
            vatValue,
            supplier,
            vatAmount,
            retailPrice,
            hiddenOptions,
            wholesalePrice,
            itemTypeOptions,
            updateRetailPrice,
            updateWholesalePrice,
            priceCalculationTypeOptions
        } = usePriceListItem();

        //button options
        const allStatusButtonOptions = ref({
            icon: "globe",
            text: "Svi",
            onClick: () => {
                tableInstance.value.clearFilter();
            }
        });
        const activeStatusButtonOptions = ref({
            text: "Aktivni",
            onClick: () => {
                tableInstance.value.filter(["status", "=", "active"]);
            }
        });
        const deletedStatusButtonOptions = ref({
            text: "Obrisani",
            onClick: () => {
                tableInstance.value.filter(["status", "=", "deleted"]);
            }
        });
        const initialStateAddButtonOptions = ref({
            icon: "add",
            hint: "Novi dokument",
            onClick: () => {
                addInitialState();
            }
        });
        const initialStateItemAddButtonOptions = ref({
            icon: "add",
            onClick: () => {
                addInitialStateItem();
            }
        });
        const initialStateRefreshButtonOptions = ref({
            icon: "refresh",
            hint: "Osvježi podatke",
            onClick: () => {
                refreshData();
            }
        });
        const columnChooserButtonOptions = ref({
            icon: "columnchooser",
            hint: "Odaberi stupce za prikaz",
            onClick: () => {
                openColumnChooser();
            }
        });
        const addNewPriceListItemButtonOptions = ref({
            icon: "add",
            hint: "Dodaj novu robu",
            onClick: () => openAddNewPriceListItemPopup()
        });

        //toolbar items
        const initialStatePopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    type: "success",
                    icon: "check",
                    onClick: () => saveNewInitialState()
                }
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    onClick: () => cancelInitialState()
                }
            }
        ]);
        const initialStateItemPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    type: "success",
                    icon: "check",
                    onClick: () => saveNewInitialStateItem()
                }
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    onClick: () => cancelInitialStateItem()
                }
            }
        ]);
        const addPopupToolbarItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    type: "success",
                    icon: "check",
                    onClick: () => saveNewPriceListItem()
                }
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    onClick: () => cancelNewPriceListItem()
                }
            }
        ]);
        const importPriceListItemPopupItems = ref([
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Spremi",
                    type: "success",
                    icon: "check",
                    onClick: () => saveInitialStateItems()
                }
            },
            {
                widget: "dxButton",
                location: "after",
                toolbar: "bottom",
                options: {
                    text: "Odustani",
                    type: "danger",
                    icon: "remove",
                    onClick: () => cancelInitialStateItems()
                }
            }
        ]);

        //form item options
        const businessYearSelectBoxOptions = ref({
            dataSource: businessYear,
            valueExpr: "id",
            readOnly: true
        });
        const dateSelectOptions = ref({
            onInitialized: (e) => {
                dateSelectInstance.value = e.component;
            },
            onValueChanged: (e) => {
                selectedDate.value = e.value;
            }
        });
        const warehouseStatusOptions = ref({
            disabled: true,
            onInitialized: (e) => {
                warehouseStatusInstance.value = e.component;
            }
        });
        const numberOptions = ref({
            disabled: true,
            onInitialized: (e) => {
                numberInstance.value = e.component;
            },
            onValueChanged: () => {
                numberInstance.value.option("disabled", false);
            }
        });
        const warehouseSelectBoxOptions = ref({
            dataSource: warehouse,
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi skladište",
            onInitialized: (e) => {
                warehouseInstance.value = e.component;
            },
            onSelectionChanged: async (e) => {
                if (!isEditing.value) {
                    if (!warehouseInstance.value.option("disabled")) {
                        const loadOptions = {
                            warehouseId: e.selectedItem.id,
                            businessYearId: businessYearId.value
                        };
                        const res = await initialState.value.load(loadOptions);
                        if (res.length > 0) {
                            numberInstance.value.option(
                                "value",
                                res[0].number + 1
                            );
                            number.value = res[0].number + 1;
                        } else {
                            numberInstance.value.option("value", 1);
                            number.value = 1;
                        }
                    }
                }
            }
        });
        const priceListItemSelectBoxOptions = ref({
            dataSource: new DataSource({
                store: priceListItem.value,
                paginate: true,
                pageSize: 30
            }),
            displayExpr: (data) => {
                if (data) return data.code + " | " + data.name;
            },
            valueExpr: "id",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi stavku",
            onInitialized: (e) => {
                priceListItemInstance.value = e.component;
            },
            onValueChanged: async (e) => {
                const selectedWarehouseId =
                    warehouseInstance.value.option("value");
                if (selectedWarehouseId) {
                    const loadOptions = {
                        priceListItemId: e.value,
                        warehouseId: selectedWarehouseId
                    };
                    const res = await warehouseItemStatus.value.load(
                        loadOptions
                    );
                    if (res.length > 0) {
                        warehouseStatusInstance.value.option(
                            "value",
                            res[0].qty
                        );
                    } else {
                        warehouseStatusInstance.value.option("value", 0);
                    }
                } else {
                    warehouseStatusInstance.value.option("value", 0);
                }
                const res = await priceListItem.value.byKey(e.value);
                if (res.wholesalePrice !== null) {
                    priceInstance.value.option("value", res.wholesalePrice);
                } else {
                    priceInstance.value.option("value", 1);
                }
                priceListItemId.value = e.value;
            }
        });
        const priceListSelectBoxOptions = ref({
            dataSource: new DataSource({
                store: priceList.value,
                paginate: true,
                pageSize: 30
            }),
            valueExpr: "id",
            displayExpr: "name",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi cijenik"
        });
        const qtyOptions = ref({
            value: qty.value,
            onInitialized: (e) => {
                qtyInstance.value = e.component;
                qtyInstance.value.option("value", qty.value);
            },
            onValueChanged: (e) => {
                qty.value = e.value;
                qtyInstance.value.option("value", e.value);
                updateTotalValue();
            }
        });
        const priceOptions = ref({
            value: price.value,
            format: "###,###,###,##0.00",
            onInitialized: (e) => {
                priceInstance.value = e.component;
            },
            onValueChanged: (e) => {
                price.value = e.value;
                priceInstance.value.option("value", e.value);
                updateTotalValue();
            }
        });
        const priceTotalOptions = ref({
            value: priceTotal.value,
            format: "###,###,###,##0.00",
            // readOnly: true,
            onInitialized: (e) => {
                priceTotalInstance.value = e.component;
            },
            onValueChanged: (e) => {
                priceTotal.value = e.value;
            }
        });
        const vatAmountOptions = {
            value: vatAmount.value,
            format: "###,###,###,##0.00",
            readOnly: true,
            onInitialized: (e) => {
                vatAmountInstance.value = e.component;
            }
        };
        const vatIdOptions = {
            dataSource: tax.value,
            valueExpr: "id",
            displayExpr: "taxValue",
            placeholder: "Odaberi stopu poreza",
            onValueChanged: async (e) => {
                if (e.value !== null) {
                    vatId.value = e.value;
                    formData.value.vatId = e.value;
                    await updateWholesalePrice(
                        wholesalePrice.value,
                        wholesalePriceInstance.value,
                        retailPriceInstance.value,
                        vatAmountInstance.value
                    );
                }
            }
        };
        const wholesalePriceOptions = {
            value: wholesalePrice.value,
            format: "###,###,###,##0.00",
            // format: { style: 'currency'},
            onInitialized: (e) => {
                wholesalePriceInstance.value = e.component;
                wholesalePriceInstance.value.option(
                    "value",
                    wholesalePrice.value
                );
            },
            onValueChanged: async (e) => {
                if (e.value !== null)
                    await updateWholesalePrice(
                        e,
                        wholesalePriceInstance.value,
                        retailPriceInstance.value,
                        vatAmountInstance.value
                    );
            }
        };
        const retailPriceOptions = {
            value: retailPrice.value,
            format: "###,###,###,##0.00",
            onInitialized: (e) => {
                retailPriceInstance.value = e.component;
                retailPriceInstance.value.option("value", retailPrice.value);
            },
            onValueChanged: async (e) => {
                if (e.value !== null)
                    await updateRetailPrice(
                        e,
                        retailPriceInstance.value,
                        wholesalePriceInstance.value,
                        vatAmountInstance.value
                    );
            }
        };
        const supplierSelectBoxOptions = ref({
            dataSource: new DataSource({
                store: supplier.value,
                paginate: true,
                pageSize: 30
            }),
            valueExpr: "id",
            displayExpr: "companyName",
            searchEnabled: true,
            searchMode: "contains",
            placeholder: "Odaberi dobavljača"
        });

        //instances
        const getInitialStateTableInstance = (e) => {
            tableInstance.value = e.component;
        };
        const getInitialStateItemTableInstance = (e) => {
            itemTableInstance.value = e.component;
        };
        const getAddPopupInstance = (e) => {
            addPopupInstance.value = e.component;
        };
        const getAddFormInstance = (e) => {
            addFormInstance.value = e.component;
        };
        const getImportPriceListItemPopupInstance = (e) => {
            importPopupInstance.value = e.component;
        };
        const getFileUploaderInstance = (e) => {
            fileUploaderInstance.value = e.component;
        };
        const getContextMenuInstance = (e) => {
            contextMenuInstance.value = e.component;
        };
        //initial state table methods
        const onFocusedRowChangedInitialState = (e) => {
            selectedInitialStateWarehouseId.value =
                e.row !== undefined ? e.row.data.warehouseId : null;
            if (e.row) {
                selectedRowKey.value = e.row.data.id;
                if (selectedRowKey.value !== null) {
                    eventBus.emit(
                        "newFocusedRowInitialState",
                        selectedRowKey.value
                    );
                }
            } else {
                eventBus.emit("newFocusedRowInitialState", null);
            }
        };
        const addInitialState = () => {
            duplicateInitialStateData.value = null;
            tableInstance.value.addRow();
        };
        const duplicateInitialState = (e) => {
            duplicateInitialStateData.value = e.row.data;
            tableInstance.value.addRow();
        };
        const editInitialState = (e) => {
            tableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelInitialState = () => {
            tableInstance.value.cancelEditData();
            qty.value = 1;
        };
        const deleteInitialState = async (e) => {
            let res = confirm(
                "Jeste li sigurni da želite obrisati početno stanje?"
            );
            res.then(async (c) => {
                if (c) {
                    await handleRowRemovingInitialState(e);
                    tableInstance.value.refresh();
                }
            });
        };
        const onInitNewRowInitialState = (e) => {
            const now = new Date().toISOString();
            if (duplicateInitialStateData.value !== null) {
                e.data = duplicateInitialStateData.value;
                priceListItemId.value = null;
                setTimeout(() => {
                    dateSelectInstance.value.option("value", now);
                }, 100);
            } else {
                e.data = {
                    businessYearId: Number(businessYearId.value)
                };
                priceListItemId.value = null;
                tableInstance.value.option("focusedRowIndex", -1);
                initialStateItemTableData.value = [];
                setTimeout(() => {
                    dateSelectInstance.value.option("value", now);
                }, 100);
            }
        };
        const saveNewInitialState = () => {
            isEditing.value = false;
            tableInstance.value.saveEditData();
        };
        const onRowInsertingInitialState = (e) => {
            handleRowInsertingInitialState(e, number.value, selectedDate.value);
        };
        const onRowInsertedInitialState = async (e) => {
            await handleRowInsertedInitialState(e);
            qty.value = 1;
            tableInstance.value.refresh();
        };
        const onEditingStartInitialState = async (e) => {
            isEditing.value = true;
            await handleEditingStartInitialState(e);
            const now = new Date();
            setTimeout(() => {
                tableInstance.value.cellValue(
                    e.component.getRowIndexByKey(e.key),
                    "modifiedDate",
                    now.toISOString()
                );
                warehouseInstance.value.option("disabled", true);
                dateSelectInstance.value.option("value", e.data.date);
            }, 100);
            priceListItemId.value = null;
        };
        const onEditCanceledInitialState = () => {
            handleEditCanceledInitialState();
            isEditing.value = false;
            tableInstance.value.refresh();
        };
        const onRowUpdatingInitialState = async (e) => {
            if (selectedDate.value !== e.oldData.date) {
                e.newData.date = selectedDate.value;
            }
            await handleRowUpdatingInitialState(e);
            initialStateItemTableData.value = [];
            qty.value = 1;
        };
        const onRowUpdatedInitialState = () => {
            eventBus.emit("newInitialStateItemAdded");
            tableInstance.value.refresh();
        };
        const onRowRemovingInitialState = async (e) => {
            await handleRowRemovingInitialState(e);
        };
        const exportInitialState = () => {
            let res = confirm("Ispisati početno stanje u pdf formatu?");
            res.then((e) => {
                if (e)
                    eventBus.emit("exportInitialState", selectedRowKey.value);
            });
        };

        //initial state item table methods
        const addInitialStateItem = () => {
            itemTableInstance.value.addRow();
        };
        const editInitialStateItem = (e) => {
            itemTableInstance.value.editRow(e.row.rowIndex);
        };
        const cancelInitialStateItem = () => {
            handleCancelInitialStateItem();
            itemTableInstance.value.cancelEditData();
            qty.value = 1;
        };
        const deleteInitialStateItem = (e) => {
            initialStateItemTableData.value.splice(e.row.rowIndex, 1);
            if (e.row.data.id) {
                rowsToBeDeleted.value.push(e.row.data);
            }
            itemTableInstance.value.refresh();
        };
        const saveNewInitialStateItem = () => {
            handleSaveNewInitialStateItem(
                qty.value,
                price.value,
                priceTotal.value
            );
            itemTableInstance.value.saveEditData();
            qty.value = 1;
        };
        const onRowInsertingInitialStateItem = (e) => {
            handleRowInsertingInitialStateItem(
                e,
                qty.value,
                price.value,
                priceTotal.value,
                priceListItemId.value
            );
        };
        const onEditingStartInitialStateItem = (e) => {
            qty.value = e.data.qty;
            handleEditingStartInitialStateItem(e);
            price.value = e.data.price;
            priceListItemId.value = null;
            setTimeout(() => {
                priceListItemInstance.value.option("disabled", true);
            }, 100);
        };
        const setRowIndexItem = (rowData) => {
            if (!itemTableInstance.value) return "";
            const currentPageIndex = itemTableInstance.value.pageIndex();
            const pageSize = itemTableInstance.value.pageSize();
            const data = itemTableInstance.value.getDataSource().items();
            const rowIndexInPage = data.findIndex((item) => {
                if (item.__KEY__) {
                    return item.__KEY__ === rowData.__KEY__;
                } else if (item.id) {
                    return item.id === rowData.id;
                }
            });

            return currentPageIndex * pageSize + (rowIndexInPage + 1);
        };

        //button methods
        const openContextMenu = (e) => {
            rowEvent.value = e;
            const targetElement = e.event.currentTarget;
            contextMenuInstance.value.option("items", getContextMenuItems());
            contextMenuInstance.value.option("position", {
                my: "top right",
                at: "bottom left",
                of: targetElement
            });
            contextMenuInstance.value.option("target", targetElement);
            contextMenuInstance.value.show();
        };
        const refreshData = () => {
            const message = "Podaci osvježeni!";
            tableInstance.value.refresh();
            notify({ message, width: 450 }, "success");
        };
        const openColumnChooser = () => {
            tableInstance.value.showColumnChooser();
        };
        const openAddNewPriceListItemPopup = () => {
            addPopupInstance.value.show();
        };
        const importInitialStateItems = () => {
            importPopupInstance.value.show();
        };

        //general methods
        const onFileSelection = async (e) => {
            const now = new Date().toISOString();
            const schema = {
                "ID Stavke": {
                    prop: "priceListItemId",
                    type: Number
                },
                "Zadnja n. cijena": {
                    prop: "price",
                    type: (value) => parseFloat(parseFloat(value).toFixed(2))
                },
                "Kol.": {
                    prop: "qty",
                    type: (value) => parseFloat(parseFloat(value).toFixed(2))
                },
                Ukupno: {
                    prop: "priceTotal",
                    type: (value) => parseFloat(parseFloat(value).toFixed(2))
                }
            };

            const file = e.value[0];
            if (file) {
                try {
                    let fileData = [];
                    if (file.name.endsWith(".xlsx")) {
                        const rows = await readXlsxFile(file, { schema });
                        fileData = rows.rows;
                    } else {
                        throw new Error("Unsupported file type");
                    }
                    uploadedFileData.value = fileData;
                    uploadedFileData.value.forEach((item) => {
                        item.createdDate = now;
                        item.modifiedDate = now;
                        item.createdById = admin.value.id;
                        item.modifiedById = admin.value.id;
                        item.initialStateId = selectedRowKey.value;
                        item.status = "active";
                    });
                } catch (error) {
                    console.error("Error reading file:", error);
                }
            }
        };
        const saveInitialStateItems = async () => {
            if (uploadedFileData.value !== null) {
                const isValidItem = (item) => {
                    return (
                        Object.hasOwn(item, "createdById") &&
                        typeof item.createdById === "number" &&
                        Object.hasOwn(item, "createdDate") &&
                        typeof item.createdDate === "string" &&
                        Object.hasOwn(item, "initialStateId") &&
                        typeof item.initialStateId === "number" &&
                        Object.hasOwn(item, "modifiedById") &&
                        typeof item.modifiedById === "number" &&
                        Object.hasOwn(item, "modifiedDate") &&
                        typeof item.modifiedDate === "string" &&
                        Object.hasOwn(item, "price") &&
                        typeof item.price === "number" &&
                        Object.hasOwn(item, "priceListItemId") &&
                        typeof item.priceListItemId === "number" &&
                        Object.hasOwn(item, "priceTotal") &&
                        typeof item.priceTotal === "number" &&
                        Object.hasOwn(item, "qty") &&
                        typeof item.qty === "number" &&
                        Object.hasOwn(item, "status") &&
                        typeof item.status === "string"
                    );
                };

                const filteredItems =
                    uploadedFileData.value.filter(isValidItem);

                if (filteredItems.length === 0) {
                    notify(
                        {
                            message:
                                "Nisu pronađene stavke koje odgovaraju strukturi. Provjerite podatke.",
                            width: 450,
                            closeOnClick: true
                        },
                        "warning",
                        5000
                    );
                    return;
                }

                const importStore = useImportItemStore();
                try {
                    const payload = {
                        domainId: admin.value.domainId,
                        warehouseId: selectedInitialStateWarehouseId.value,
                        items: filteredItems
                    };
                    const res = await importStore.importInitialStateItems(
                        payload
                    );
                    if (res.success) {
                        notify(
                            {
                                message: res.message,
                                width: 450,
                                closeOnClick: true
                            },
                            "success",
                            10000
                        );
                        await initialState.value.update(selectedRowKey.value, {
                            priceTotal: res.totalPrice
                        });
                        uploadedFileData.value = null;
                        fileUploaderInstance.value.clear();
                        importPopupInstance.value.hide();
                        eventBus.emit("newInitialStateItemAdded");
                        tableInstance.value.refresh();
                    } else {
                        notify(
                            {
                                message: res.message,
                                width: 450,
                                closeOnClick: true
                            },
                            "error",
                            10000
                        );
                        throw new Error(res.message || "Error updating items.");
                    }
                } catch (error) {
                    console.error("Error inserting data:", error);
                    notify(
                        {
                            message: error.message,
                            width: 450,
                            closeOnClick: true
                        },
                        "error",
                        10000
                    );
                }
            }
        };

        const cancelInitialStateItems = () => {
            importPopupInstance.value.hide();
        };
        const saveNewPriceListItem = async () => {
            const now = new Date().toISOString();
            const isValid = addFormInstance.value.validate("addPriceListItem");
            if (isValid.isValid) {
                formData.value.vatId = Number(formData.value.vatId);
                formData.value = {
                    ...formData.value,
                    vat: vatValue.value * 100,
                    createdById: admin.value.id,
                    modifiedById: admin.value.id,
                    createdDate: now,
                    modifiedDate: now,
                    domainId: admin.value.domainId
                };
                try {
                    await priceListItem.value.insert(formData.value);
                    addPopupInstance.value.hide();
                    formData.value = {
                        vatId: 1,
                        hidden: 0,
                        priceCalculationType: "Fiksna cijena"
                    };
                    wholesalePrice.value = 0;
                    retailPrice.value = 0;
                    vatId.value = 1;
                    vatValue.value = 0.25;
                    vatAmount.value = 0;
                } catch (error) {
                    console.error("Error inserting data:", error);
                    const message = error.message;
                    notify(
                        { message, width: 450, closeOnClick: true },
                        "error",
                        10000
                    );
                    return null;
                }
            }
        };
        const cancelNewPriceListItem = () => {
            formData.value = {
                vatId: 1,
                hidden: 0,
                priceCalculationType: "Fiksna cijena"
            };

            wholesalePrice.value = 0;
            retailPrice.value = 0;
            vatId.value = 1;
            vatValue.value = 0.25;
            vatAmount.value = 0;
            addPopupInstance.value.hide();
        };
        const onPopupShown = () => {
            warehouseInstance.value.focus();
        };
        const updateTotalValue = () => {
            priceTotal.value = (
                Number(qty.value) * Number(price.value)
            ).toFixed(2);
            priceTotalInstance.value.option("value", Number(priceTotal.value));
        };
        const isDeleted = (e) => {
            return e.row.data.status === "deleted" ? true : false;
        };
        const isNotDeleted = (e) => {
            return e.row.data.status === "active" ? false : true;
        };
        const onPopupHiding = () => {
            fileUploaderInstance.value.clear();
        };
        const getContextMenuItems = () => {
            return [
                {
                    text: "Uredi",
                    icon: "edit",
                    onClick: () => {
                        editInitialState(rowEvent.value);
                    },
                    disabled: isNotDeleted(rowEvent.value)
                },
                {
                    text: "Dupliciraj",
                    icon: "copy",
                    onClick: () => {
                        duplicateInitialState(rowEvent.value);
                    }
                },
                {
                    text: "Izradi PDF",
                    icon: "pdffile",
                    onClick: () => {
                        exportInitialState();
                    },
                    disabled: isNotDeleted(rowEvent.value)
                },
                {
                    text: "Učitaj stavke",
                    icon: "import",
                    onClick: () => {
                        importInitialStateItems();
                    },
                    disabled: isNotDeleted(rowEvent.value)
                },
                {
                    text: "Obriši",
                    icon: "trash",
                    onClick: () => {
                        deleteInitialState(rowEvent.value);
                    },
                    disabled: isNotDeleted(rowEvent.value)
                }
            ];
        };

        //events
        eventBus.on("initialStateItems", (data) => {
            initialStateItemTableData.value = data;
        });
        return {
            getContextMenuItems,
            getContextMenuInstance,
            openContextMenu,
            setRowIndexItem,
            initialStateItemAddButtonOptions,
            initialStateRefreshButtonOptions,
            columnChooserButtonOptions,
            initialStatePopupToolbarItems,
            initialStateItemPopupToolbarItems,
            getInitialStateItemTableInstance,
            onRowUpdatedInitialState,
            onEditCanceledInitialState,
            onEditingStartInitialState,
            onRowRemovingInitialState,
            onRowInsertingInitialStateItem,
            editInitialState,
            onRowUpdatingInitialState,
            deleteInitialState,
            onEditingStartInitialStateItem,
            onRowInsertingInitialState,
            onRowInsertedInitialState,
            editInitialStateItem,
            domain,
            domainUser,
            warehouse,
            businessYear,
            initialState,
            onFocusedRowChangedInitialState,
            initialStateItem,
            deleteInitialStateItem,
            priceListItem,
            warehouseItemStatus,
            columnChooserMode,
            priceTotalFormat,
            businessYearSelectBoxOptions,
            dateSelectOptions,
            warehouseStatusOptions,
            numberOptions,
            warehouseSelectBoxOptions,
            priceListItemSelectBoxOptions,
            qtyOptions,
            priceOptions,
            priceTotalOptions,
            getInitialStateTableInstance,
            initialStateAddButtonOptions,
            onInitNewRowInitialState,
            onPopupShown,
            initialStateItemTableData,
            exportInitialState,
            duplicateInitialState,
            isDeleted,
            isNotDeleted,
            addPopupToolbarItems,
            priceListSelectBoxOptions,
            getAddPopupInstance,
            getAddFormInstance,
            cancelNewPriceListItem,
            addNewPriceListItemButtonOptions,
            vatAmount,
            retailPrice,
            hiddenOptions,
            wholesalePrice,
            itemTypeOptions,
            updateRetailPrice,
            vatIdOptions,
            updateWholesalePrice,
            priceCalculationTypeOptions,
            vatAmountOptions,
            wholesalePriceOptions,
            retailPriceOptions,
            formData,
            supplierSelectBoxOptions,
            importPriceListItemPopupItems,
            getImportPriceListItemPopupInstance,
            getFileUploaderInstance,
            importInitialStateItems,
            onFileSelection,
            onPopupHiding,
            allStatusButtonOptions,
            activeStatusButtonOptions,
            deletedStatusButtonOptions
        };
    }
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#initialStateTable {
    max-width: 100%;
}
#initialStateItemTable {
    max-width: 100%;
}
.table-name {
    font-size: 1.6rem;
    text-align: center;
    margin: 1vh;
}
.split {
    border: 0.2px solid #1c1c1c;
    margin: 0.5vh;
    /* width: 84.5vw; */
    max-width: 100vw;
}
</style>
