import CustomStore from "devextreme/data/custom_store";
import { sendRequest } from "@/helpers/sendRequestHelper";
import config from "../../config.json";

export const eInvoiceStatusChanges = new CustomStore({
    key: "id",
    load: async (loadOptions) => {
        try {
            let filterCondition;
            if (loadOptions.invoiceId && loadOptions.invoiceId !== null) {
                filterCondition = {
                    where: {
                        invoiceId: loadOptions.invoiceId
                    }
                };
                const res = await sendRequest(
                    `${config.API_URL}/${
                        config.PATHS.E_INVOICE_STATUS_CHANGES
                    }?filter=${JSON.stringify(filterCondition)}`
                );
                return res;
            }
        } catch (error) {
            console.error("Error loading data:", error);
            throw error;
        }
    }
    // byKey: async (key) => {
    //     try {
    //         const res = await sendRequest(
    //             `${config.API_URL}/${config.PATHS.BUSINESS_YEAR}/${key}`
    //         );
    //         return res;
    //     } catch (error) {
    //         console.error("Error loading data:", error);
    //         throw error;
    //     }
    // },
});
