<template>
    <div class="table-container">
        <h1>Dobar dan, {{ adminUsername }}! 😁</h1>
        <!-- <button @click="testEchoB2G">TEST RECEIVER LIST</button> -->
    </div>
</template>
<script>
import { useDomainUserStore } from "@/stores/customDomainUserStore";
import { ref } from "vue";
export default {
    name: "dashboardComponent",
    setup() {
        const domainUserStore = useDomainUserStore();
        const admin = domainUserStore.getDomainUser;
        const adminUsername = ref(admin.username);
        // const testEchoB2G = () => {
        //     const req = new Request("http://localhost:3010/test", {
        //         method: "POST"
        //     });
        //     fetch(req).then(async (res) => {
        //         console.log(await res.json());
        //     });
        // };
        return {
            adminUsername
            // testEchoB2G
        };
    }
};
</script>
<style scoped>
.table-container {
    margin: 2vh;
    max-width: 100%;
}
#offerItemTable {
    width: 100%;
}
</style>
