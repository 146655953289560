import { defineStore } from "pinia";
import config from "../../config.json";
import { sendRequest } from "@/helpers/sendRequestHelper";

export const useEInvoiceStore = defineStore("eInvoiceStore", {
    actions: {
        async sendOutgoingInvoice(values) {
            try {
                const res = await sendRequest(
                    `${config.API_URL}/${config.PATHS.SEND_OUTGOING_INVOICE}`,
                    "POST",
                    {
                        values: values
                    }
                );
                return res;
            } catch (error) {
                console.log(error);
                // throw error;
            }
        },
        async checkEInvoiceStatus(values) {
            try {
                const res = await sendRequest(
                    `${config.API_URL}/${config.PATHS.CHECK_OUTGOING_INVOICE_STATUS}`,
                    "POST",
                    {
                        values: values
                    }
                );
                return res;
            } catch (error) {
                console.log(error);
                // throw error;
            }
        },
        async sendOutgoingInvoiceB2G(values) {
            try {
                const res = await sendRequest(
                    `${config.API_URL}/${config.PATHS.SEND_OUTGOING_INVOICE_B2G}`,
                    "POST",
                    {
                        values: values
                    }
                );
                return res;
            } catch (error) {
                console.log(error);
                // throw error;
            }
        },
        async checkEInvoiceStatusB2G(values) {
            try {
                const res = await sendRequest(
                    `${config.API_URL}/${config.PATHS.CHECK_OUTGOING_INVOICE_STATUS_B2G}`,
                    "POST",
                    {
                        values: values
                    }
                );
                return res;
            } catch (error) {
                console.log(error);
                // throw error;
            }
        }
    }
});
